
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { useI18n } from "vue-i18n";
import { salesOrderOverviewFormData } from "@/core/directive/interface/salesOrder";
import { ApiSalesOrders } from "@/core/api";
import {
  getSalesOrderFulfillmentStatus,
  getSalesOrderPriorityLevelMap,
  getSalesOrderPriorityLevelSvgStyleMap,
  getSalesOrderStatusMap,
  getSettlementStatusMap,
} from "@/core/directive/function/salesOrder";
import { CommonDateType, NumberOrString } from "@/core/directive/type/common";
import {
  SalesOrderDistributionMode,
  SalesOrderNewStatus,
  SalesOrderPriorityLevel,
} from "@/core/directive/type/salesOrder";
import {
  CommonArrayToString,
  commonExportFile,
  formatDate,
  formatDateTime,
  firstnameToPinyin,
  lastnameToPinyin,
  containsChinese,
} from "@/core/directive/function/common";
import { isAuditView } from "@/core/directive/function/order";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";
import WindowResize from "@/components/layout/WindowResize.vue";
import { getOwnerLabel } from "@/core/directive/function/user";

export default defineComponent({
  name: "sales-order-order-overview-detail",
  components: { PermissionCommon, WindowResize },
  setup() {
    const { t } = useI18n();

    const route = useRoute();

    const loading = ref(true);
    const disabledExportOrderNotice = ref<boolean>(false);
    const SalesOrderOverviewData = ref(
      Object.assign({}, salesOrderOverviewFormData)
    );

    const getCompanyData = async () => {
      const { data } = await ApiSalesOrders.getCompanyInfo({
        order_id: route.params.id,
      });
      if (data.code == 0) {
        if (data.data.sales_order_identifier) {
          SalesOrderOverviewData.value = data.data;
        }
      }
    };

    const getPinyinName = computed(() => {
      if (
        containsChinese(
          SalesOrderOverviewData.value.sales_order_account_information
            .user_account_name
        )
      ) {
        const cnNameArr =
          SalesOrderOverviewData.value.sales_order_account_information.user_account_name.split(
            " "
          );
        const cnLastname = cnNameArr[0];
        const cnFirstname = cnNameArr[1];
        return (
          lastnameToPinyin(cnLastname) + " " + firstnameToPinyin(cnFirstname)
        );
      } else {
        return SalesOrderOverviewData.value.sales_order_account_information
          .user_account_name;
      }
    });

    const fileExportOrderNotice = async () => {
      if (disabledExportOrderNotice.value) true;
      disabledExportOrderNotice.value = true;
      ApiSalesOrders.exportOrderNotice({
        order_id: route.params.id,
      })
        .then((data) => {
          disabledExportOrderNotice.value = false;
          commonExportFile(data);
        })
        .catch((error) => {
          disabledExportOrderNotice.value = false;
          console.log(error);
        });
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getCompanyData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const orderStatus = computed(() => {
      return (status: number) => {
        return getSalesOrderStatusMap(status, t)?.color;
      };
    });

    const fulfillmentStatus = computed(() => {
      return (status: NumberOrString) => {
        return getSalesOrderFulfillmentStatus(status, t);
      };
    });

    const settlementStatus = computed(() => {
      return (status: number) => {
        return getSettlementStatusMap(status, t)?.color;
      };
    });

    const userAccount = computed(() => {
      return (
        SalesOrderOverviewData.value.sales_order_account_information.user_account_name
          .split(" ")
          .join("") +
        SalesOrderOverviewData.value.sales_order_account_information
          .user_mobile_number
      );
    });

    const getOrderTotals = computed(() => {
      return (str: string) => {
        let result = "";
        str = Number(str).toFixed(2);
        result = str.substring(str.lastIndexOf("."), str.length);
        if (SalesOrderOverviewData.value.base_currency_code == "€") {
          result = result.replace(".", ",");
        }
        return result;
      };
    });

    const isServiceOrder = computed(() => {
      let isflag = false;
      if (route.path.indexOf("/double-sale-order/order-overview/") != -1) {
        isflag = true;
      }
      return isflag;
    });

    const getStoreRouter = computed(() => {
      let routerUrl = "";
      if (route.path.indexOf("/double-sale-order/order-overview/") != -1) {
        routerUrl = "/double-sale-order/order-overview/";
      } else if (route.path.indexOf("/sales-order/order-verification/") != -1) {
        routerUrl = "/sales-order/order-verification/";
      } else {
        routerUrl = "/sales-order/order-overview/";
      }
      return routerUrl;
    });

    const customFormatDate = (date: CommonDateType, format = "YYYY-MM-DD") => {
      return formatDate(date, format);
    };

    const isDoubleSale = computed(() => {
      return route.path.includes("double-sale-order");
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      getFormInfo();
    });

    return {
      t,
      formatDate,
      formatDateTime,
      CommonArrayToString,
      getOwnerLabel,
      SalesOrderPriorityLevel,
      SalesOrderNewStatus,
      SalesOrderDistributionMode,
      getSalesOrderPriorityLevelMap,
      getSalesOrderPriorityLevelSvgStyleMap,
      loading,
      disabledExportOrderNotice,
      SalesOrderOverviewData,
      orderStatus,
      fulfillmentStatus,
      settlementStatus,
      userAccount,
      getOrderTotals,
      fileExportOrderNotice,
      isServiceOrder,
      getStoreRouter,
      getFormInfo,
      customFormatDate,
      isDoubleSale,
      isAuditView,
      getPinyinName,
    };
  },
});
